import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import {HTMLContent} from './Content'
import remark from 'remark'
import remarkHtml from 'remark-html'
import NewsPreview from './NewsPreview'
import Content from './Content'
import Navbar from './Navbar'
import Footer from './Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import Image from 'gatsby-image'
import Helmet from 'react-helmet'

const HomePageTemplate = ({
  content, 
  contentComponent,
  asideImages
}) => {
  const PostContent = contentComponent || Content

  function AsideImages({asideImages}) {
    if( !Array.isArray(asideImages) || !asideImages.length) {
      return (
        < ></ >
      )
    }

    return (
      <div className="column is-3 is-offset-1">
        {asideImages.map(image => (
          < >
            <Image className="asideImage" resolutions={image.image.childImageSharp.resolutions} alt={image.image.alt} />
          </ >
        ))}
        <p style={{marginTop: '2rem'}}>
          <a className="button is-primary is-block" href="https://www.harriscountyfws.org/GageDetail/Index/2010?span=24%20Hours&v=surfaceBox">Current Barker Reservoir Levels</a>
        </p>
      </div>
    )
  }

  return (
  < >
    <Helmet title="Barker Flood Prevention Advocacy Group" />
    <Navbar />
    <section className="section section__home-page">
      <div className="container content">
        <div className="columns">
          <div className="column is-6 is-offset-1 ">
              <PostContent content={content} />
              {/*
              <h2 className="has-text-centered">Want to join our advocacy group?</h2>
              <p className="has-text-centered"><Link className="button is-primary is-outlined is-size-5" to="/">register here</Link></p>
              */}

          </div>
          <AsideImages asideImages={asideImages} />
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
      <div className="columns">
        <div className="column is-2 is-offset-1">
          <Link to="/latest-updates" className="blog-button">
              <FontAwesomeIcon className="is-size-3" icon={faAngleDoubleRight} /><br />
              SEE ALL<br />
              LATEST UPDATES
            </Link>
        </div>
        <div className="column is-8">
          <NewsPreview />
        </div>
      </div>
      </div>
    </section>
    <Footer />
  </ >
)
}

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  welcomeMessage: PropTypes.string,
}

export default HomePageTemplate
