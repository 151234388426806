import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons'

const Navbar = () => (
      <StaticQuery
        query={graphql`
          {
            allMarkdownRemark(limit: 3, sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "blog-post"}}}) {
              edges {
                node {
                  excerpt(pruneLength: 400)
                  id
                  fields {
                    slug
                  }
                  frontmatter {
                    title
                    description
                    externalLink
                    thumbnail {
                      childImageSharp {
                        sizes (maxWidth: 400){
                          ...GatsbyImageSharpSizes_tracedSVG
                        }
                      }
                    }
                    templateKey
                    date(formatString: "MMMM DD, YYYY")
                  }
                }
              }
            }
          }
        `}
        render={ function(data) {
          const {edges: posts} = data.allMarkdownRemark
          


        return (
          <div className="news-preview-container">
            {posts.map(({ node: post }) =>(
              <div className="news-preview-item" key={post.id}>
              <Img sizes={post.frontmatter.thumbnail.childImageSharp.sizes} alt="" style={{height: '154px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px'}} />
              <div className="news-preview-content">
              <p className="is-size-5">{post.frontmatter.title}<br /><small className="accent-color">{post.frontmatter.date}</small></p>
              </div>
              <div className="news-preview-footer">
                {
                    (post.frontmatter.externalLink === null) ? (
                      < >
                      <Link className="button is-white" to={post.fields.slug}>Read More</Link>
                      <div class="dropdown is-hoverable is-up">
                      <div class="dropdown-trigger">
                        <button class="button is-white" aria-haspopup="true" aria-controls="dropdown-menu4">
                          <span>Share</span>
                          <span className="icon is-small">
                          <FontAwesomeIcon icon={faAngleUp} />
                          </span>
                        </button>
                      </div>
                      <div class="dropdown-menu" id="dropdown-menu4" role="menu">
                        <div class="dropdown-content">
                          <a target="_blank" rel="noreferrer noopener" href={'http://www.twitter.com/share?url=https://www.barkerfloodprevention.org'+post.fields.slug} className="dropdown-item">
                            <span className="icon">
                            <FontAwesomeIcon icon={faTwitter} />
                            </span>
                            Twitter
                          </a>
                          <hr class="dropdown-divider" />
                          <a target="_blank" rel="noreferrer noopener" href={'https://www.facebook.com/sharer/sharer.php?u=https://www.barkerfloodprevention.org'+post.fields.slug} className="dropdown-item">
                            <span className="icon">
                            <FontAwesomeIcon icon={faFacebookF} />
                            </span>
                            Facebook
                          </a>
                        </div>
                      </div>
                    </div> 
                    </ >
                    ) : (
                      < >
                      <a target="_blank" rel="noopener noreferrer" href={post.frontmatter.externalLink} className="button is-white">Read More</a>
                      <div class="dropdown is-hoverable is-up">
                  <div class="dropdown-trigger">
                    <button class="button is-white" aria-haspopup="true" aria-controls="dropdown-menu4">
                      <span>Share</span>
                      <span className="icon is-small">
                      <FontAwesomeIcon icon={faAngleUp} />
                      </span>
                    </button>
                  </div>
                  <div class="dropdown-menu" id="dropdown-menu4" role="menu">
                    <div class="dropdown-content">
                      <a target="_blank" rel="noreferrer noopener" href={'http://www.twitter.com/share?url='+post.frontmatter.externalLink} className="dropdown-item">
                        <span className="icon">
                        <FontAwesomeIcon icon={faTwitter} />
                        </span>
                        Twitter
                      </a>
                      <hr class="dropdown-divider" />
                      <a target="_blank" rel="noreferrer noopener" href={'https://www.facebook.com/sharer/sharer.php?u='+post.frontmatter.externalLink} className="dropdown-item">
                        <span className="icon">
                        <FontAwesomeIcon icon={faFacebookF} />
                        </span>
                        Facebook
                      </a>
                    </div>
                  </div>
                </div>
                </ >
                    )
                }
                
              </div>
            </div>

            ))}
            
          </div>
        )}
      }
      />
)

export default Navbar
